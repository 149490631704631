document.addEventListener("DOMContentLoaded", runVideoPlayScripts);

function runVideoPlayScripts() {
//play button
    const play = document.querySelectorAll('.small-play');
    assignEvents(play, 'click', playVideo);
}

//Add an Event Listener to Multiple Elements
function assignEvents(elems, type, event) {
    for (let i = 0; i < elems.length; i++) {
        elems[i].addEventListener(type, event, false);
    }
}

function playVideo(e) {
    const box = e.currentTarget.parentElement,
        video = box.querySelector('video');

    if(typeof(video) == 'undefined' || !video) return true;
    if (video.paused) {
        allPause();
        video.play();
        e.currentTarget.classList.add('active');
        video.addEventListener('ended', allPause);
    } else {
        video.pause();
        e.currentTarget.classList.remove('active');
    }
}

function allPause() {
    const allPlayButtons = document.querySelectorAll('.small-play');
    for (let i = 0; i < allPlayButtons.length; i++) {

        const video = allPlayButtons[i].parentElement.querySelector( 'video');
        if(video) {
            video.pause();
        }
    }
    for (let i = 0; i < allPlayButtons.length; i++) {
        allPlayButtons[i].classList.remove('active');
    }
}
